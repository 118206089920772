// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environment: 'qa',
  appUrl: 'https://ect2.qa.zonarsystems.net/',
  audience: 'http://apiqa.zonarsystems.net/',
  clientID: '6CuNFH2jm4wPasHUmoJc3QSUiCzLicng',
  domain: 'qa-login.zonarsystems.net',
  applicationId: 'a28e74dc-8d69-4c66-a8f6-ec31c0e8e67f',
  defaultPermPrefix: 'ect2',
  redirectUrl: '/',
  defaultZonarRole: '09e1d42b-63c1-43de-9487-66ab51eb31a9',
  companyApi: 'https://api-qa.zonarsystems.net/core/v1beta2',
  configApi: 'https://api-qa.zonarsystems.net/ib-api/v1',
  evirApi: 'https://api-qa.zonarsystems.net/evir/v1',
  region: 'NA',
  feature_flags: {
    isNewLanguageDictionary: true,
  },
  copyRightText: 'Zonar Systems',
  datadog: {
    enabled: false,
    applicationId: '6771fe23-e504-437a-b2cf-79d74fea313f',
    clientToken: 'pub1cc0c05dd55624b135b865328e6ca0a3',
    site: 'us5.datadoghq.com',
    service: 'inspection-builder',
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    excludedActivityUrls: ['localhost', '172.0.0.1'],
  },
  i18n: {
    supportedLanguages: ['en', 'en-GB', 'es', 'de', 'fr', 'it'],
    defaultLanguage: 'en',
    useBrowserLanguage: true,
  },
  pendo: {
    url: `https://cdn.pendo.io/agent/static/`,
    key: '47e63a49-4477-47c2-4ea6-0f032e1785d5',
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
